var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "animated fadeIn" }, [
    !_vm.isVerRespostas
      ? _c(
          "div",
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "12", lg: "12" } },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "col-md-12 p-0 mr-2",
                        attrs: {
                          "header-tag": "header",
                          "footer-tag": "footer"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_c("strong", [_vm._v("Filtros")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "p-1 mt-2" },
                          [
                            _c(
                              "b-row",
                              { staticClass: "mb-1" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "pr-md-0 mb-1 mb-md-0",
                                    attrs: { sm: 3 }
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        plain: true,
                                        options: _vm.filtro.listas.gestao
                                      },
                                      model: {
                                        value: _vm.filtro.fields.gestao,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filtro.fields,
                                            "gestao",
                                            $$v
                                          )
                                        },
                                        expression: "filtro.fields.gestao"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "pr-md-0 mb-1 mb-md-0",
                                    attrs: { sm: 3 }
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        plain: true,
                                        options: _vm.filtro.listas.centres
                                      },
                                      model: {
                                        value: _vm.filtro.fields.centres,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filtro.fields,
                                            "centres",
                                            $$v
                                          )
                                        },
                                        expression: "filtro.fields.centres"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "pr-md-0 mb-1 mb-md-0",
                                    attrs: { sm: "2" }
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        plain: true,
                                        options: _vm.filtro.listas.meses
                                      },
                                      model: {
                                        value: _vm.filtro.fields.mes,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filtro.fields,
                                            "mes",
                                            $$v
                                          )
                                        },
                                        expression: "filtro.fields.mes"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { sm: "2" } },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        plain: true,
                                        options: _vm.filtro.listas.anos
                                      },
                                      model: {
                                        value: _vm.filtro.fields.ano,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filtro.fields,
                                            "ano",
                                            $$v
                                          )
                                        },
                                        expression: "filtro.fields.ano"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { sm: "1" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "my-2 my-sm-0 text-white",
                                        class: {
                                          "btn-block": _vm.$mq === "sm"
                                        },
                                        attrs: { variant: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.search()
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-search" })]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "12", lg: "12" } },
                  [
                    _c(
                      "b-card",
                      { attrs: { "no-body": true } },
                      [
                        _c(
                          "b-card-body",
                          {
                            staticClass: "clearfix text-center text-white",
                            attrs: { "body-bg-variant": "primary" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "icon-up text-primary font-weight-bold h4 mr-3 float-left float-up",
                                staticStyle: { background: "#fff" }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      top: "23%",
                                      position: "relative"
                                    }
                                  },
                                  [_vm._v("NPS")]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "h1 mb-1 font-weight-bold",
                                staticStyle: { "margin-right": "50px" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("number")(_vm.nps_retorno.indice_nps)
                                  )
                                )
                              ]
                            ),
                            _c("div", { staticClass: "h5" }, [
                              _vm._v(
                                "Classificação: " +
                                  _vm._s(
                                    _vm.descClassificacao(
                                      _vm.nps_retorno.indice_nps
                                    )
                                  )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "margin-r color-cinza" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "6", lg: "4" } },
                  [
                    _c(
                      "b-card",
                      { attrs: { "no-body": true } },
                      [
                        _c("b-card-body", { staticClass: "clearfix" }, [
                          _c("i", {
                            staticClass:
                              "icon-up face-sad p-3 font-2xl mr-3 float-left float-up"
                          }),
                          _c("p", { staticClass: "text-center mb-1 h1" }, [
                            _vm._v(_vm._s(_vm.nps_retorno.tot_detratores))
                          ]),
                          _c("p", { staticClass: "text-center mb-1 h5" }, [
                            _vm._v("Detratores")
                          ]),
                          _c("p", { staticClass: "text-center mb-1 h5" }, [
                            _vm._v(
                              _vm._s(_vm.nps_retorno.perc_detratores) + "%"
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "6", lg: "4" } },
                  [
                    _c(
                      "b-card",
                      { attrs: { "no-body": true } },
                      [
                        _c("b-card-body", { staticClass: "clearfix" }, [
                          _c("i", {
                            staticClass:
                              "icon-up face-neutral p-3 font-2xl mr-3 float-left float-up"
                          }),
                          _c("div", { staticClass: "text-center mb-1 h1" }, [
                            _vm._v(_vm._s(_vm.nps_retorno.tot_neutros))
                          ]),
                          _c("div", { staticClass: "text-center mb-1 h5" }, [
                            _vm._v("Neutros")
                          ]),
                          _c("div", { staticClass: "text-center mb-1 h5" }, [
                            _vm._v(_vm._s(_vm.nps_retorno.perc_neutros) + "%")
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "6", lg: "4" } },
                  [
                    _c(
                      "b-card",
                      { attrs: { "no-body": true } },
                      [
                        _c("b-card-body", { staticClass: "clearfix" }, [
                          _c("i", {
                            staticClass:
                              "icon-up face-happy p-3 font-2xl mr-3 float-left float-up"
                          }),
                          _c("div", { staticClass: "text-center mb-1 h1" }, [
                            _vm._v(_vm._s(_vm.nps_retorno.tot_promotores))
                          ]),
                          _c("div", { staticClass: "text-center mb-1 h5" }, [
                            _vm._v("Promotores")
                          ]),
                          _c("div", { staticClass: "text-center mb-1 h5" }, [
                            _vm._v(
                              _vm._s(_vm.nps_retorno.perc_promotores) + "%"
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "color-cinza" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "6", lg: "3" } },
                  [
                    _c(
                      "b-card",
                      { attrs: { "no-body": true } },
                      [
                        _c(
                          "b-card-body",
                          {
                            staticClass: "clearfix",
                            staticStyle: { height: "112px" }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { sm: "4", md: "2" } }, [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-envelope p-3 mr-3 float-left float-up text-primary",
                                    staticStyle: { top: "20px" }
                                  })
                                ]),
                                _c("b-col", { attrs: { sm: "8", md: "10" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-center mb-1 h1" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.nps_retorno.tot_enviadas)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center mb-1 h5",
                                      staticStyle: { "font-size": "1rem" }
                                    },
                                    [_vm._v("Pesquisas Enviadas")]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "6", lg: "3" } },
                  [
                    _c(
                      "b-card",
                      { attrs: { "no-body": true } },
                      [
                        _c(
                          "b-card-body",
                          {
                            staticClass: "clearfix",
                            staticStyle: { height: "112px" }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { sm: "4", md: "2" } }, [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-comment p-3 font-2xl mr-3 float-left float-up text-primary"
                                  })
                                ]),
                                _c("b-col", { attrs: { sm: "8", md: "10" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-center mb-1 h1" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.nps_retorno.tot_respondidas)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center mb-1 h5",
                                      staticStyle: { "font-size": "1rem" }
                                    },
                                    [_vm._v("Pesquisas Respondidas")]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "6", lg: "3" } },
                  [
                    _c(
                      "b-card",
                      { attrs: { "no-body": true } },
                      [
                        _c(
                          "b-card-body",
                          {
                            staticClass: "clearfix",
                            staticStyle: { height: "112px" }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { sm: "4", md: "2" } }, [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-percent p-3 mr-3 float-left float-up text-primary",
                                    staticStyle: { top: "20px" }
                                  })
                                ]),
                                _c("b-col", { attrs: { sm: "8", md: "10" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-center mb-1 h1" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.nps_retorno.taxa_respostas) +
                                          "%"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center mb-1 h5",
                                      staticStyle: { "font-size": "1rem" }
                                    },
                                    [_vm._v("Taxas de Respostas")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center mb-1 h5 font-italic",
                                      staticStyle: {
                                        "font-weight": "normal",
                                        "font-size": ".9rem"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.nps_retorno.tot_respondidas
                                        ) + " respostas"
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "6", lg: "3" } },
                  [
                    _c(
                      "b-card",
                      { attrs: { "no-body": true } },
                      [
                        _c(
                          "b-card-body",
                          {
                            staticClass: "clearfix",
                            staticStyle: { height: "112px" }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { sm: "4", md: "2" } }, [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-percent p-3 mr-3 float-left float-up text-primary",
                                    staticStyle: { top: "20px" }
                                  })
                                ]),
                                _c("b-col", { attrs: { sm: "8", md: "10" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-center mb-1 h1" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.nps_retorno.taxa_comentarios
                                        ) + "%"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center mb-1 h5",
                                      staticStyle: { "font-size": "1rem" }
                                    },
                                    [_vm._v("Taxas de Comentários")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center mb-1 h5 font-italic",
                                      staticStyle: {
                                        "font-weight": "normal",
                                        "font-size": ".9rem"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.nps_retorno.tot_comentarios
                                        ) + " comentários"
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "12", lg: "12" } },
                  [
                    _c(
                      "b-card",
                      { attrs: { "header-tag": "header" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "float-left",
                            attrs: { slot: "header" },
                            slot: "header"
                          },
                          [_c("strong", [_vm._v("NPS por Loja")])]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "float-right",
                            attrs: { slot: "header" },
                            slot: "header"
                          },
                          [
                            _c(
                              "download-excel",
                              {
                                staticClass: "float-left",
                                attrs: {
                                  name: "Nps Loja-Vendedor.xls",
                                  fields: _vm.exportNpsLoja,
                                  data: _vm.items.loja_vendedor
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-download cursor-pointer",
                                  attrs: { alt: "Exportar", title: "Exportar" }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _c("b-table", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.items.loja_vendedor.length > 0,
                              expression: "items.loja_vendedor.length > 0"
                            }
                          ],
                          attrs: {
                            striped: "",
                            small: "",
                            responsive: "md",
                            items: _vm.items.loja_vendedor,
                            fields: _vm.fields.loja_vendedor
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "verresp",
                                fn: function(data) {
                                  return [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-comment fa-lg text-primary cursor-pointer",
                                      on: {
                                        click: function($event) {
                                          return _vm.verRespostas(
                                            data.item.loja_id,
                                            data.item.vendedor
                                          )
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3467444462
                          )
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.items.loja_vendedor.length === 0,
                                expression: "items.loja_vendedor.length === 0"
                              }
                            ],
                            staticClass: "alert alert-secondary text-center",
                            staticStyle: {
                              "margin-top": "9vh",
                              "margin-bottom": "10vh"
                            }
                          },
                          [_vm._v("Nenhum resultado encontrado.")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-card",
              { staticClass: "mr-md-2" },
              [
                _c(
                  "div",
                  {
                    staticClass: "text-primary",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "d-flex-inline mr-auto" }, [
                        _c("strong", [
                          _vm._v("Avaliações dos Clientes por Notas")
                        ])
                      ]),
                      _c("div", { staticClass: "d-flex-inline ml-auto" }, [
                        _c("i", {
                          staticClass:
                            "fa fa-download text-primary cursor-pointer",
                          attrs: { title: "Exportar" },
                          on: {
                            click: function($event) {
                              return _vm.exportimage(
                                "chart-bar-01",
                                "Avaliações - Por Notas"
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]
                ),
                _vm.loaded
                  ? _c("bar", {
                      attrs: {
                        chartdata: _vm.charts.NOTA,
                        height: 350,
                        displayLegend: false,
                        chartId: "chart-bar-01"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "12", md: "12" } },
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex-inline mr-auto text-primary"
                                },
                                [_c("strong", [_vm._v("Evolução do NPS")])]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex-inline ml-auto" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-download text-primary cursor-pointer",
                                    attrs: { title: "Exportar" },
                                    on: {
                                      click: function($event) {
                                        return _vm.exportimage(
                                          "chart-line-01",
                                          "Evolução do NPS"
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm.loaded
                          ? _c("line-chart", {
                              attrs: {
                                chartdata: _vm.charts.EVOLUCAO,
                                height: 245,
                                moneyFormat: false,
                                chartId: "chart-line-01",
                                displayLegend: false
                              }
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "d-flex-inline mr-auto my-legend" },
                          [
                            _c(
                              "strong",
                              {
                                staticStyle: {
                                  color: "#787878",
                                  "margin-left": "10px"
                                }
                              },
                              [_vm._v("Zonas")]
                            ),
                            _c("div", { staticClass: "legend-scale" }, [
                              _c("ul", { staticClass: "legend-labels" }, [
                                _c("li", [
                                  _c("span", {
                                    staticClass: "mr-1",
                                    staticStyle: {
                                      background: "rgb(246, 19, 8)"
                                    }
                                  }),
                                  _vm._v("Crítica")
                                ]),
                                _c("li", [
                                  _c("span", {
                                    staticClass: "mr-1",
                                    staticStyle: { background: "#fbcc1a" }
                                  }),
                                  _vm._v("Aperfeiçoamento")
                                ]),
                                _c("li", [
                                  _c("span", {
                                    staticClass: "mr-1",
                                    staticStyle: { background: "#0000ff" }
                                  }),
                                  _vm._v("Qualidade")
                                ]),
                                _c("li", [
                                  _c("span", {
                                    staticClass: "mr-1",
                                    staticStyle: { background: "#45b920" }
                                  }),
                                  _vm._v("Excelência")
                                ])
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.isVerRespostas
      ? _c(
          "div",
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "12", lg: "12" } },
                  [
                    _c(
                      "b-card",
                      { attrs: { "header-tag": "header" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_c("strong", [_vm._v(_vm._s(_vm.tituloVerResp))])]
                        ),
                        _c("b-table", {
                          attrs: {
                            striped: "",
                            small: "",
                            responsive: "md",
                            items: _vm.items.nps_respostas,
                            fields: _vm.fields.ver_respostas
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "nota",
                                fn: function(data) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("number")(data.item.nota)
                                        ) +
                                        "\n              "
                                    ),
                                    _c("i", {
                                      staticClass: "fa",
                                      class: [
                                        data.item.compara_nota === "IGUAL"
                                          ? "fa-minus"
                                          : data.item.compara_nota === "MAIOR"
                                          ? "fa-arrow-up"
                                          : data.item.compara_nota === "MENOR"
                                          ? "fa-arrow-down"
                                          : "",
                                        data.item.compara_nota === "IGUAL"
                                          ? "text-warning"
                                          : data.item.compara_nota === "MAIOR"
                                          ? "text-success"
                                          : data.item.compara_nota === "MENOR"
                                          ? "text-danger"
                                          : ""
                                      ]
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4259651585
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mb-1" },
              [
                _c(
                  "b-col",
                  { attrs: { sm: "1" } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "my-2 my-sm-0 text-white",
                        attrs: { variant: "primary" },
                        on: {
                          click: function($event) {
                            _vm.isVerRespostas = false
                            _vm.loadCharts()
                          }
                        }
                      },
                      [_c("i", [_vm._v("Voltar")])]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }