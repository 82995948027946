<template>
  <div class="animated fadeIn">
    <div v-if="!isVerRespostas">
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" footer-tag="footer" class="col-md-12 p-0 mr-2">
            <div slot="header">
              <strong>Filtros</strong>
            </div>
            <div class="p-1 mt-2">
              <b-row class="mb-1">
                <b-col :sm="3" class="pr-md-0 mb-1 mb-md-0">
                  <b-form-select
                    :plain="true"
                    v-model="filtro.fields.gestao"
                    :options="filtro.listas.gestao"
                  ></b-form-select>
                </b-col>
                <b-col :sm="3" class="pr-md-0 mb-1 mb-md-0">
                  <b-form-select
                    :plain="true"
                    v-model="filtro.fields.centres"
                    :options="filtro.listas.centres"
                  ></b-form-select>
                </b-col>
                <b-col sm="2" class="pr-md-0 mb-1 mb-md-0">
                  <b-form-select
                    :plain="true"
                    v-model="filtro.fields.mes"
                    :options="filtro.listas.meses"
                  ></b-form-select>
                </b-col>
                <b-col sm="2">
                  <b-form-select
                    :plain="true"
                    v-model="filtro.fields.ano"
                    :options="filtro.listas.anos"
                  ></b-form-select>
                </b-col>
                <b-col sm="1">
                  <b-button
                    variant="primary"
                    class="my-2 my-sm-0 text-white"
                    :class="{'btn-block': $mq === 'sm'}"
                    @click="search()"
                  >
                    <i class="fa fa-search"></i>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card :no-body="true">
            <b-card-body class="clearfix text-center text-white" body-bg-variant="primary">
              <div
                class="icon-up text-primary font-weight-bold h4 mr-3 float-left float-up"
                style="background: #fff;"
              >
                <span style="top: 23%;position: relative;">NPS</span>
              </div>
              <div
                class="h1 mb-1 font-weight-bold"
                style="margin-right: 50px;"
              >{{ nps_retorno.indice_nps | number }}</div>
              <div class="h5">Classificação: {{descClassificacao(nps_retorno.indice_nps)}}</div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="margin-r color-cinza">
        <b-col cols="12" sm="6" lg="4">
          <b-card :no-body="true">
            <b-card-body class="clearfix">
              <i class="icon-up face-sad p-3 font-2xl mr-3 float-left float-up"></i>
              <p class="text-center mb-1 h1">{{nps_retorno.tot_detratores}}</p>
              <p class="text-center mb-1 h5">Detratores</p>
              <p class="text-center mb-1 h5">{{ nps_retorno.perc_detratores }}%</p>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" sm="6" lg="4">
          <b-card :no-body="true">
            <b-card-body class="clearfix">
              <i class="icon-up face-neutral p-3 font-2xl mr-3 float-left float-up"></i>
              <div class="text-center mb-1 h1">{{nps_retorno.tot_neutros}}</div>
              <div class="text-center mb-1 h5">Neutros</div>
              <div class="text-center mb-1 h5">{{ nps_retorno.perc_neutros }}%</div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" sm="6" lg="4">
          <b-card :no-body="true">
            <b-card-body class="clearfix">
              <i class="icon-up face-happy p-3 font-2xl mr-3 float-left float-up"></i>
              <div class="text-center mb-1 h1">{{nps_retorno.tot_promotores}}</div>
              <div class="text-center mb-1 h5">Promotores</div>
              <div class="text-center mb-1 h5">{{ nps_retorno.perc_promotores }}%</div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="color-cinza">
        <b-col cols="12" sm="6" lg="3">
          <b-card :no-body="true">
            <b-card-body class="clearfix" style="height: 112px;">
              <b-row>
                <b-col sm="4" md="2">
                  <i class="fa fa-envelope p-3 mr-3 float-left float-up text-primary" style="top: 20px;"></i>
                </b-col>
                <b-col sm="8" md="10">
                  <div class="text-center mb-1 h1">{{nps_retorno.tot_enviadas}}</div>
                  <div class="text-center mb-1 h5" style="font-size: 1rem">Pesquisas Enviadas</div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" sm="6" lg="3">
          <b-card :no-body="true">
            <b-card-body class="clearfix" style="height: 112px;">
              <b-row>
                <b-col sm="4" md="2">
                  <i class="fa fa-comment p-3 font-2xl mr-3 float-left float-up text-primary"></i>
                </b-col>
                <b-col sm="8" md="10">
                  <div class="text-center mb-1 h1">{{nps_retorno.tot_respondidas}}</div>
                  <div class="text-center mb-1 h5" style="font-size: 1rem">Pesquisas Respondidas</div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" sm="6" lg="3">
          <b-card :no-body="true">
            <b-card-body class="clearfix" style="height: 112px;">
              <b-row>
                <b-col sm="4" md="2">
                  <i class="fa fa-percent p-3 mr-3 float-left float-up text-primary" style="top: 20px;"></i>
                </b-col>
                <b-col sm="8" md="10">
                  <div class="text-center mb-1 h1">{{ nps_retorno.taxa_respostas }}%</div>
                  <div class="text-center mb-1 h5" style="font-size: 1rem">Taxas de Respostas</div>
                  <div class="text-center mb-1 h5 font-italic" style="font-weight: normal; font-size: .9rem">{{nps_retorno.tot_respondidas}} respostas</div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" sm="6" lg="3">
          <b-card :no-body="true">
            <b-card-body class="clearfix" style="height: 112px;">
              <b-row>
                <b-col sm="4" md="2">
                  <i class="fa fa-percent p-3 mr-3 float-left float-up text-primary" style="top: 20px;"></i>
                </b-col>
                <b-col sm="8" md="10">
                  <div class="text-center mb-1 h1">{{ nps_retorno.taxa_comentarios }}%</div>
                  <div class="text-center mb-1 h5" style="font-size: 1rem">Taxas de Comentários</div>
                  <div class="text-center mb-1 h5 font-italic" style="font-weight: normal; font-size: .9rem">{{nps_retorno.tot_comentarios}} comentários</div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="12" lg="12">
          <b-card header-tag="header">
            <div slot="header" class="float-left">
              <strong>NPS por Loja</strong>
            </div>
            <div slot="header" class="float-right">
              <download-excel
                name="Nps Loja-Vendedor.xls"
                class="float-left"
                :fields="exportNpsLoja"
                :data="items.loja_vendedor"
                >
                  <i class="fa fa-download cursor-pointer" alt="Exportar" title="Exportar"></i>
              </download-excel>
            </div>
            <b-table
              striped
              small
              responsive="md"
              :items="items.loja_vendedor"
              :fields="fields.loja_vendedor"
              v-show="items.loja_vendedor.length > 0"
            >
              <template slot="verresp" slot-scope="data">
                <i class="fa fa-comment fa-lg text-primary cursor-pointer" @click="verRespostas(data.item.loja_id, data.item.vendedor)"></i>
              </template>
            </b-table>
            <div
              class="alert alert-secondary text-center"
              v-show="items.loja_vendedor.length === 0"
              style="margin-top: 9vh; margin-bottom: 10vh;"
            >Nenhum resultado encontrado.</div>
          </b-card>
        </b-col>
      </b-row>

      <b-card class="mr-md-2">
        <div slot="header" class="text-primary">
          <div class="d-flex">
            <div class="d-flex-inline mr-auto">
              <strong>Avaliações dos Clientes por Notas</strong>
            </div>
            <div class="d-flex-inline ml-auto">
              <i
                class="fa fa-download text-primary cursor-pointer"
                @click="exportimage('chart-bar-01', `Avaliações - Por Notas`)"
                title="Exportar"
              ></i>
            </div>
          </div>
        </div>
        <bar :chartdata="charts.NOTA" :height="350" :displayLegend="false" chartId="chart-bar-01" v-if="loaded" />
      </b-card>

      <b-row>
        <b-col cols="12" sm="12" md="12">
          <b-card>
            <div slot="header">
              <div class="d-flex">
                <div class="d-flex-inline mr-auto text-primary">
                  <strong>Evolução do NPS</strong>
                </div>
                <div class="d-flex-inline ml-auto">
                  <i
                    class="fa fa-download text-primary cursor-pointer"
                    @click="exportimage('chart-line-01', `Evolução do NPS`)"
                    title="Exportar"
                  ></i>
                </div>
              </div>
            </div>
            <line-chart
              :chartdata="charts.EVOLUCAO"
              :height="245"
              :moneyFormat="false"
              chartId="chart-line-01"
              :displayLegend="false"
              v-if="loaded"
            />
            <div class="d-flex-inline mr-auto my-legend">
              <strong style="color: #787878;margin-left:10px">Zonas</strong>
              <div class="legend-scale">
                <ul class="legend-labels">
                  <li><span class="mr-1" style="background:rgb(246, 19, 8);"></span>Crítica</li>
                  <li><span class="mr-1" style="background:#fbcc1a;"></span>Aperfeiçoamento</li>
                  <li><span class="mr-1" style="background:#0000ff;"></span>Qualidade</li>
                  <li><span class="mr-1" style="background:#45b920;"></span>Excelência</li>
                </ul>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="isVerRespostas">
      <b-row>
        <b-col cols="12" sm="12" lg="12">
          <b-card header-tag="header">
            <div slot="header">
              <strong>{{tituloVerResp}}</strong>
            </div>
            <b-table
              striped
              small
              responsive="md"
              :items="items.nps_respostas"
              :fields="fields.ver_respostas"
            >
              <template slot="nota" slot-scope="data">
                {{ data.item.nota | number }}
                <i
                  class="fa"
                  :class="[
                  data.item.compara_nota === 'IGUAL' ? 'fa-minus' : (data.item.compara_nota === 'MAIOR' ? 'fa-arrow-up' :  (data.item.compara_nota === 'MENOR' ? 'fa-arrow-down' : '')),
                  data.item.compara_nota === 'IGUAL' ? 'text-warning' : (data.item.compara_nota === 'MAIOR' ? 'text-success' : (data.item.compara_nota === 'MENOR' ? 'text-danger' : ''))
                ]"
                />
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col sm="1">
          <b-button
            variant="primary"
            class="my-2 my-sm-0 text-white"
            @click="isVerRespostas = false; loadCharts()"
          >
            <i>Voltar</i>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import session from "@/shared/session";
import { mes } from "@/shared/string";
import API from "@/core/api";
import cTable from "@/core/components/Table.vue";
import { setInterval } from "timers";
import Bar from "@/core/components/charts/Bar";
import LineChart from "@/core/components/charts/LineChart";

export default {
  name: "Nps",
  components: {
    cTable,
    Bar,
    LineChart
  },
  data: () => {
    return {
      filtro: {
        listas: {
          gestao: session.get("gestoes"),
          centres: session.get("lojas"),
          meses: [...Array(12).keys()].map(a => {
            return { value: a + 1, text: mes(a + 1) };
          }),
          anos: [],
          intervalo: []
        },
        fields: {
          gestao: 0,
          centres: 0,
          mes: moment().format("M"),
          ano: moment().format("YYYY")
        }
      },
      fields: {
        loja_vendedor: [
          { key: "descricao", label: "Descrição", sortable: true },
          { key: "indice_nps", label: "NPS", class: "text-center", number: true, },
          {
            key: "tot_detratores",
            label: "Detratores",
            sortable: true,
            number: true,
            thClass: "text-right",
            tdClass: "text-right"
          },
          {
            key: "tot_neutros",
            label: "Neutros",
            sortable: true,
            number: true,
            thClass: "text-right",
            tdClass: "text-right"
          },
          {
            key: "tot_promotores",
            label: "Promotores",
            sortable: true,
            number: true,
            thClass: "text-right",
            tdClass: "text-right"
          },
          {
            key: "tot_enviadas",
            label: "Pesquisas Enviadas",
            sortable: true,
            number: true,
            thClass: "text-right",
            tdClass: "text-right"
          },
          {
            key: "tot_respondidas",
            label: "Pesquisas Respondidas",
            sortable: true,
            number: true,
            thClass: "text-right",
            tdClass: "text-right"
          },
          {
            key: "taxa_respostas",
            label: "Taxa de Respostas",
            sortable: true,
            numeric: true,
            thClass: "text-right",
            tdClass: "text-right"
          },
          {
            key: "verresp",
            label: "Ver Respostas",
            thClass: "text-center",
            tdClass: "text-center"
          }
        ],
        ver_respostas: [
          { key: "data_hora_retorno", label: "Data Resposta" },
          { key: "cliente", label: "Cliente", class: "text-left" },
          { key: "vendedor", label: "Vendedor", class: "text-left" },
          { key: "nota", label: "Nota", class: "text-left" },
          { key: "comentario", label: "Comentário", class: "text-left" }
        ]
      },
      charts: {
        evolucao: {}
      },
      items: {
        loja_vendedor: [],
        nota: [],
        evolucao: [],
        nps_respostas: []
      },
      nps_retorno: {},
      isVerRespostas: false,
      chartFluxoAtendimento: [],
      loaded: false,
      tituloVerResp: ''
    };
  },
  computed: {
    exportNpsLoja: function() {
      const obj = {};
      return this.fields.loja_vendedor.reduce((item, key) => {
        if (key['label'] !== '') {
          obj[key["label"]] = key["key"];
        }
        return obj;
      }, {});
    },
  },
  methods: {
    async search() {
      try {
        this.loaded = null;

        const npsRetornoAPI = new API("api/nps-retorno");
        const { data } = await npsRetornoAPI.get({
          ...this.parseFilters(this.filtro.fields)
        });
        this.nps_retorno = data[0];

        const retornoLojaAPI = new API("api/nps-retorno_loja_vend");
        const data2 = await retornoLojaAPI.get({
          ...this.parseFilters(this.filtro.fields)
        });
        this.items.loja_vendedor = data2.data[0].records;

        const notaAPI = new API("api/nps_avaliacao_nota");
        const data3 = await notaAPI.get({
          ...this.parseFilters(this.filtro.fields)
        });
        this.items.nota = data3.data[0];

        const evolucaoAPI = new API("api/nps_evolucao");
        const data4 = await evolucaoAPI.get({
          ...this.parseFilters(this.filtro.fields)
        });
        this.items.evolucao = data4.data[0];
        this.loadCharts();
      } catch (e) {
        console.error(e);
      }
    },
    async verRespostas(pLoja, funcionario) {
      try {
        this.loaded = null;
        this.isVerRespostas = true;
    
        const descMes = this.filtro.listas.meses.filter(({value}) => String(value) === String(this.filtro.fields.mes))[0].text;
        const descAno = this.filtro.listas.anos.filter(({value}) => String(value) === String(this.filtro.fields.ano))[0].text;
        this.tituloVerResp = 'Ver Respostas: ' + descMes + '/' + descAno + ' - Loja ' + pLoja;
        const respostasAPI = new API("api/nps_ver_respostas");
        const params = {
          ...this.parseFilters(this.filtro.fields),
          loja: pLoja,
          funcionario
        };
        const { data } = await respostasAPI.get(params);
        this.items.nps_respostas = data[0].records;
      } catch (e) {
        console.error(e);
      }
    },
    descClassificacao(valor) {
      if (valor < 0) {
        return "Zona Crítica";
      } else if (valor >= 0 && valor < 50) {
        return "Zona de Aperfeiçoamento";
      } else if (valor >= 50 && valor < 75) {
        return "Zona de Qualidade";
      } else if (valor >= 75) {
        return "Zona de Excelência";
      } else {
        return "";
      }
    },
    validaPeriodo() {
      const { dataDia } = this.filtro.fields;
      let invalid = false;

      if (dataDia === "" || !moment(dataDia).isValid()) {
        invalid = true;
      }

      if (invalid) {
        return swal({
          text: "Data inválida."
        });
      }
      return true;
    },
    loadCharts() {
      this.charts = {
        NOTA: {
          labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          datasets: [
            {
              label: "Por Cliente",
              backgroundColor: [
                "rgb(246, 19, 8)",
                "rgb(246, 19, 8)",
                "rgb(246, 19, 8)",
                "rgb(246, 19, 8)",
                "rgb(246, 19, 8)",
                "rgb(246, 19, 8)",
                "rgb(246, 19, 8)",
                "#fbcc1a",
                "#fbcc1a",
                "#45b920",
                "#45b920"
              ],
              data: Object.values(this.items.nota.records[0])
            }
          ]
        },
        EVOLUCAO: {
          labels: this.items.evolucao.records[0].mes_descricao[0],
          datasets: [
            {
              label: "Índice NPS",
              borderColor: this.items.evolucao.records[0].mes_valor[0].map(
                v => {
                  let color = "";
                  if (v < 0) {
                    color = "rgb(246, 19, 8)";
                  } else if (v >= 0 && v < 50) {
                    color = "#fbcc1a";
                  } else if (v >= 50 && v < 75) {
                    color = "#0000ff";
                  } else if (v >= 75) {
                    color = "#45b920";
                  }
                  return color;
                }
              ),
              backgroundColor: "transparent",
              pointHoverBackgroundColor: "#fff",
              borderWidth: 2,
              fill: true,
              data: this.items.evolucao.records[0].mes_valor[0]
            }
          ]
        }
      };

      this.loaded = true;
    },

    async getAnosPeriodo() {
      const anosMetasAPI = new API("api/anos-periodos");
      const listAnosMetasNps = await anosMetasAPI.get({ ...this.parseFilters(this.filtro.fields) });
      const { data : { listAnosNps : anos } } = listAnosMetasNps;

      if (anos.length > 0) {
        this.filtro.listas.anos = anos.map(d => {
          return {
            value: d.periodos_anuais,
            text: d.periodos_anuais
          };
        });
      }
    },

    parseFilters(filters) {
      let filter = { ...filters };
      const { centres, periodo } = filters;

      const lojas = this.filtro.listas.centres.map(c => c.value).join(",");
      filter.centres = centres === 0 ? lojas : centres;
      if (typeof filter.centres === "number") {
        filter.listaPorVendedor = true;
      }
      return filter;
    },
    exportimage(id, name) {
      // Set White Background
      let chart = document.getElementById(id)
      var ctx = chart.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = "#FFF";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();

      // Download image
      let canvas = document.getElementById(id).toDataURL('image/png')
      let link = document.createElement('a')
      link.download = name || 'Grafico'
      link.href = canvas
      link.click()
    }
  },
  async created() {
    const profile = (session.get('profile'))
    const { loja_vendedor } = this.fields
    if (profile.nivel === 'Vendedor') {
      this.fields.loja_vendedor = loja_vendedor.filter(f => {
        return f.key !== 'verresp'
      })
    }
    
    await this.getAnosPeriodo();
    await this.search();
  },
};
</script>

<style scoped>
.margin-r .h1 {
  margin-right: 76px;
}
.color-cinza {
  color: #535d67 !important;
}
.color-cinza .fa {
  font-size: 3.5rem !important;
  top: 15px;
  position: absolute;
}

.my-legend .legend-scale ul {
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
}
.my-legend .legend-scale ul li {
  display: block;
  float: left;
  width: 25;
  margin-bottom: 6px;
  margin-left: 12px;
  text-align: center;
  font-size: 80%;
  list-style: none;
  color: #787878;
  font-weight: bold;
}
.my-legend ul.legend-labels li span {
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}
</style>
